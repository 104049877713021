a,
div,
button,
[type="button"] {
  -webkit-appearance: none;
}

.floating-button {
  display: flex;
}

.floating-button a {
  display: grid;
  position: relative;
  font-size: 4vw;
  text-decoration: none;
  align-self: center;
  left: 3%;
  font-family: "Poppins";
  white-space: nowrap;
}
.phoneIconMobile {
  align-self: center;
}
.floating-button .button-title {
  display: grid;
  grid-template-columns: 10% 110%;
  position: fixed;
  text-align:center;
  justify-content: center;
}

.floating-button .button-title p {
  margin-top: 3vw;
  font-size: 4vw;
  text-decoration: none;
  font-family: "Poppins";
}

.floating-button a:hover {
  text-decoration: none;
}


@media screen and (min-width: 1001px) {

  .floating-button {
    display:block;
  }

  .floating-button .button-title{
    display: flex;
  }

  .floating-button a {
    font-size: 1.5vw;
    margin-top: -1vw;
  }

  .floating-button .button-title p {
    margin-top: 1vw;
    margin-left: 0.5vw;
    font-size: 1.5vw;
    text-decoration: none;
    font-family: "Poppins";
  }

}
